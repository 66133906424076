<template>
  <div class="project">
    <div>
      <el-timeline>
        <el-timeline-item center timestamp="2024/9" placement="top">
          <el-card>
            <div class="column-layout">
              <div>
                <h4>{{ $t('ProjectsView.personalWebsite') }}</h4>
                <p>{{ $t('ProjectsView.personalWebsiteDate') }}</p>
              </div>
              <div class="flex-grow"></div>
              <el-button :size="buttonSize" color="#626aef" @click="goToPersonalWebsite">
                {{ $t('ProjectsView.viewDetail') }}
              </el-button>
            </div>
          </el-card>
        </el-timeline-item>
        <el-timeline-item center timestamp="2024/8" placement="top">
          <el-card>
            <div class="column-layout">
              <div>
                <h4>{{ $t('ProjectsView.skyTakeOut') }}</h4>
                <p>{{ $t('ProjectsView.skyTakeOutDate') }}</p>
              </div>
              <div class="flex-grow"></div>
              <el-button :size="buttonSize" color="#626aef" @click="goToSkyTakeOut">
                {{ $t('ProjectsView.viewDetail') }}
              </el-button>
            </div>
          </el-card>
        </el-timeline-item>
        <el-timeline-item center timestamp="2023/12" placement="top">
          <el-card>
            <div class="column-layout">
              <div>
                <h4>{{ $t('ProjectsView.bostonApp') }}</h4>
                <p>{{ $t('ProjectsView.bostonAppDate') }}</p>
              </div>
              <div class="flex-grow"></div>
              <el-button :size="buttonSize" color="#626aef" @click="goToBostonApp">
                {{ $t('ProjectsView.viewDetail') }}
              </el-button>
            </div>
          </el-card>
        </el-timeline-item>
      </el-timeline>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router';
import '../css/ProjectsViewCss.css';

const router = useRouter();

function goToSkyTakeOut() {
  router.push("/projects/skyTakeOut");
}

function goToBostonApp() {
  router.push("/projects/bostonApp");
}

function goToPersonalWebsite() {
  router.push("/projects/personalWebsite");
}

const isDesktop = ref(window.innerWidth >= 769);
const buttonSize = ref(isDesktop.value ? 'large' : 'small');

const updateLayout = () => {
  isDesktop.value = window.innerWidth >= 769;
  buttonSize.value = isDesktop.value ? 'large' : 'small';
};

onMounted(() => {
  updateLayout();
  console.log('Initial isDesktop:', isDesktop.value);
  window.addEventListener('resize', updateLayout);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateLayout);
});
</script>