<template>
  <div class="skyTakeOut">
    <el-page-header :title="$t('SkyTakeOut.backButtonText')" @back="goBack">
      <template #breadcrumb>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/projects' }">
            {{ $t('SkyTakeOut.breadcrumbProjects') }}
          </el-breadcrumb-item>
          <el-breadcrumb-item id="title">{{ $t('SkyTakeOut.breadcrumbSkyTakeOut') }}</el-breadcrumb-item>
        </el-breadcrumb>
      </template>
      <template #content>
        <span>{{ $t('SkyTakeOut.breadcrumbSkyTakeOut') }}</span>
      </template>
    </el-page-header>
    <section class="module">
      <h1>{{ $t('SkyTakeOut.title') }}</h1>
      <p>{{ $t('SkyTakeOut.githubLink') }} <el-link type="primary" href="https://gitee.com/liu-ying-chen/sky-take-out"
          id="custom-link">https://gitee.com/liu-ying-chen/sky-take-out</el-link></p>
    </section>

    <section class="module">
      <h3>{{ $t('SkyTakeOut.overviewTitle') }}</h3>
      <p>{{ $t('SkyTakeOut.overviewText1') }}</p>
      <p>{{ $t('SkyTakeOut.overviewText2') }}</p>
      <ul>
        <li>{{ $t('SkyTakeOut.overviewText3') }}</li>
        <li>{{ $t('SkyTakeOut.overviewText4') }}</li>
        <li>{{ $t('SkyTakeOut.overviewText5') }}</li>
      </ul>
      <p>{{ $t('SkyTakeOut.overviewText6') }}</p>
    </section>

    <section class="module">
      <h3>{{ $t('SkyTakeOut.techStackTitle') }}</h3>
      <p>{{ $t('SkyTakeOut.techStackText') }}</p>
    </section>

    <section class="module">
      <h3>{{ $t('SkyTakeOut.uiDesignTitle') }}</h3>
      <div>
        <el-carousel :interval="5000" :direction="carouselDirection" arrow="always" type="card"
          indicator-position="none" :height="carouselHeight">
          <el-carousel-item v-for="(img, index) in images" :key="index">
            <img :src="img" class="carousel-image" />
          </el-carousel-item>
        </el-carousel>
      </div>
    </section>

    <section class="module">
      <h3>{{ $t('SkyTakeOut.featuresTitle') }}</h3>
      <ol>
        <li>
          <strong>{{ $t('SkyTakeOut.adminFunctionsTitle') }}</strong>
          <ul>
            <li><strong>{{ $t('SkyTakeOut.loginLogout') }}</strong> {{ $t('SkyTakeOut.adminLoginLogoutText') }}</li>
            <li><strong>{{ $t('SkyTakeOut.employeeManagement') }}</strong> {{ $t('SkyTakeOut.employeeManagementText') }}
            </li>
            <li><strong>{{ $t('SkyTakeOut.categoryManagement') }}</strong> {{ $t('SkyTakeOut.categoryManagementText') }}
            </li>
            <li><strong>{{ $t('SkyTakeOut.dishManagement') }}</strong> {{ $t('SkyTakeOut.dishManagementText') }}</li>
            <li><strong>{{ $t('SkyTakeOut.setMealManagement') }}</strong> {{ $t('SkyTakeOut.setMealManagementText') }}
            </li>
            <li><strong>{{ $t('SkyTakeOut.orderManagement') }}</strong> {{ $t('SkyTakeOut.orderManagementText') }}</li>
            <li><strong>{{ $t('SkyTakeOut.dataStatistics') }}</strong> {{ $t('SkyTakeOut.dataStatisticsText') }}</li>
          </ul>
        </li>
        <li>
          <strong>{{ $t('SkyTakeOut.userFunctionsTitle') }}</strong>
          <ul>
            <li><strong>{{ $t('SkyTakeOut.loginLogout') }}</strong> {{ $t('SkyTakeOut.userLoginLogoutText') }}</li>
            <li><strong>{{ $t('SkyTakeOut.orderMenu') }}</strong> {{ $t('SkyTakeOut.orderMenuText') }}</li>
            <li><strong>{{ $t('SkyTakeOut.shoppingCart') }}</strong> {{ $t('SkyTakeOut.shoppingCartText') }}</li>
            <li><strong>{{ $t('SkyTakeOut.orderPayment') }}</strong> {{ $t('SkyTakeOut.orderPaymentText') }}</li>
            <li><strong>{{ $t('SkyTakeOut.personalInformation') }}</strong> {{ $t('SkyTakeOut.personalInformationText')
              }}
            </li>
          </ul>
        </li>
      </ol>
    </section>

    <section class="module">
      <h3>{{ $t('SkyTakeOut.architectureTitle') }}</h3>
      <img src="../../img/skyTakeOut/architecture.png" alt="Architecture Diagram" id="architecture-diagram">
    </section>

    <section class="module">
      <h3>{{ $t('SkyTakeOut.installationTitle') }}</h3>
      <ol>
        <li>{{ $t('SkyTakeOut.installationSteps[0]') }}</li>
        <li>{{ $t('SkyTakeOut.installationSteps[1]') }}</li>
        <li>{{ $t('SkyTakeOut.installationSteps[2]') }}</li>
        <li>{{ $t('SkyTakeOut.installationSteps[3]') }}</li>
        <li>{{ $t('SkyTakeOut.installationSteps[4]') }}</li>
        <li>{{ $t('SkyTakeOut.installationSteps[5]') }}</li>
      </ol>
    </section>

    <section class="module">
      <h3>{{ $t('SkyTakeOut.futureImprovementsTitle') }}</h3>
      <ol>
        <li>{{ $t('SkyTakeOut.futureImprovements[0]') }}</li>
      </ol>
    </section>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router';

import image1 from '../../img/skyTakeOut/LoginPage.png';
import image2 from '../../img/skyTakeOut/EmployeeManagementPage.png';
import image3 from '../../img/skyTakeOut/DishManagementPage.png';
import image4 from '../../img/skyTakeOut/CategoryManagementPage.png';
import image5 from '../../img/skyTakeOut/MealManagementPage.png';

const images = ref([
  image1,
  image2,
  image3,
  image4,
  image5,
]);

function goBack() {
  router.push('/projects');
}

const isDesktop = ref(window.innerWidth >= 769);
const carouselHeight = ref(isDesktop.value ? '470px' : '360px');
const carouselDirection = ref(isDesktop.value ? 'horizontal' : 'vertical');

const updateLayout = () => {
  isDesktop.value = window.innerWidth >= 769;
  carouselHeight.value = isDesktop.value ? '470px' : '360px';
  carouselDirection.value = isDesktop.value ? 'horizontal' : 'vertical';
};

onMounted(() => {
  updateLayout();
  window.addEventListener('resize', updateLayout);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateLayout);
});

const router = useRouter();
</script>

<style scoped>
.skyTakeOut {
  padding-left: 40px;
  padding-right: 40px;
}

#title {
  font-family: "Poppins";
  font-size: 14px;
}

#custom-link {
  font-size: 14px;
}

.module {
  margin-top: 40px;
}

span {
  font-size: 14px;
}

.module h1 {
  font-size: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.module p {
  font-size: 14px;
  font-weight: 500;
}

.carousel-image {
  width: 100%;
  height: 420px;
  display: block;
  margin: 0 auto;
  object-fit: contain;
  border: 3px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box;
}

ol li {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
}

ul li {
  font-size: 14px;
  font-weight: 500;
}

#architecture-diagram {
  height: 600px;
  width: auto;
}

@media (max-width: 768px) {
  .skyTakeOut {
    padding-left: 20px;
    padding-right: 20px;
  }

  #title {
    font-size: 10px;
  }

  #custom-link {
    font-size: 12px;
  }

  span {
    font-size: 12px;
  }

  .module {
    margin-top: 20px;
  }

  .module h1 {
    font-size: 24px;
  }

  .module p {
    font-size: 12px;
  }

  .carousel-image {
    width: 100%;
    height: auto;
    border: 1px solid #ccc;
    border-radius: 8px;
  }

  ol li {
    font-size: 12px;
  }

  ul li {
    font-size: 12px;
    font-weight: 500;
  }

  #architecture-diagram {
    height: 200px;
    width: auto;
  }
}
</style>