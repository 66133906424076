<template>
  <div class="home">
    <div class="body">
      <div id="screen-part1">
        <div class="column-layout">
          <h1 id="home-view-title">{{ $t('homeView.opening') }} </h1>
          <img src="../img/SDE.png" alt="SDE" id="sde" />
        </div>
        <h1 id="home-view-title">{{ $t('homeView.myName') }}</h1>

        <p style="margin-top: 40px;">{{ $t('homeView.description') }}</p>

        <div class="column-layout" style="margin-top: 40px;">

          <button type="button" id="hire_me" @click="dialogFormVisible = true">{{ $t('homeView.hireMe') }}</button>

          <el-dialog v-model="dialogFormVisible" :title="$t('homeView.contactMe')" :width="dialogWidth">
            <el-form :model="form">
              <el-form-item :label="$t('homeView.companyName')" :label-width="formLabelWidth">
                <el-input v-model="form.company_name" autocomplete="off" />
              </el-form-item>
              <el-form-item :label="$t('homeView.yourName')" :label-width="formLabelWidth">
                <el-input v-model="form.hr_name" autocomplete="off" />
              </el-form-item>
              <el-form-item :label="$t('homeView.contactEmail')" :label-width="formLabelWidth">
                <el-input v-model="form.email" autocomplete="off" />
              </el-form-item>
              <el-form-item :label="$t('homeView.message')" :label-width="formLabelWidth">
                <el-input type="textarea" :rows="6" v-model="form.message" autocomplete="off" />
              </el-form-item>
            </el-form>
            <template #footer>
              <div class="dialog-footer">
                <el-button @click="handleCancel">{{ $t('homeView.cancel') }}</el-button>
                <el-button type="primary" @click="handleConfirm">
                  {{ $t('homeView.confirm') }}
                </el-button>
              </div>
            </template>
          </el-dialog>


          <button type="button" id="projects" @click="goToProjects">{{ $t('homeView.projects') }} &#8599;</button>
        </div>

        <div class="small-module" style="margin-top: 40px;">
          <h2>2000+</h2>
          <p>{{ $t('homeView.leetcode') }} </p>
        </div>

        <div id="last-module">
          <div class="small-module">
            <h2>6+</h2>
            <p>{{ $t('homeView.experience') }}</p>
          </div>

          <div class="small-module" id="contact">
            <h2>{{ $t('homeView.contact') }}</h2>
            <p>{{ $t('homeView.email') }}</p>
          </div>
        </div>
      </div>
      <div id="screen-part2">
        <img src="../img/toolkit.png" alt="toolkit" id="toolkit" />
      </div>
    </div>
  </div>
</template>

<style scoped>
p strong {
  font-weight: bold;
  color: grey;
}

:deep(.el-form-item .el-form-item__label) {
  font-family: "Poppins" !important;
  font-size: 12px !important;
  color: #303133;
}
</style>

<script lang="ts" setup>
import { useRouter } from 'vue-router';
import { reactive, ref, onMounted, onBeforeUnmount, computed } from 'vue'
import '../css/HomeViewCss.css';

import emailjs from 'emailjs-com';

import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const { locale } = useI18n();

const isDesktop = ref(window.innerWidth >= 769);

const updateLayout = () => {
  isDesktop.value = window.innerWidth >= 769;
};

onMounted(() => {
  updateLayout();
  window.addEventListener('resize', updateLayout);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateLayout);
});

const dialogWidth = computed(() => isDesktop.value ? '500px' : '300px');

const router = useRouter();

const goToProjects = () => {
  router.push("/projects");
};

const dialogFormVisible = ref(false)
const formLabelWidth = computed(() => {
  if (isDesktop.value) {
    return '140px';
  } else {
    return locale.value === 'zh' ? '80px' : '120px';
  }
});


const form = reactive({
  company_name: '',
  hr_name: '',
  email: '',
  message: ''
})

const handleCancel = function () {
  dialogFormVisible.value = false;
  clearFormData();
};

const handleConfirm = function () {
  dialogFormVisible.value = false;
  sendEmail();
};

const clearFormData = function () {
  form.company_name = '';
  form.hr_name = '';
  form.email = '';
  form.message = '';
};

const sendEmail = () => {
  const serviceID = 'service_p2z3s2j';
  const templateID = 'template_v9d60cm';
  const publicKey = 'yqBZvdpT48fsh_OgV';

  emailjs.send(serviceID, templateID, {
    company_name: form.company_name,
    hr_name: form.hr_name,
    email: form.email,
    message: form.message
  }, publicKey)
    .then((response) => {
      console.log('SUCCESS!', response.status, response.text);
      dialogFormVisible.value = false;
      clearFormData();
      alert(t('homeView.messageSentSuccessfully'));
    }, (error) => {
      console.error('FAILED...', error);
      alert(t('homeView.messageSentFailed'));
    });
};


</script>