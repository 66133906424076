<template>
  <div class="photography">
    <el-container style="height: 100%">
      <el-aside>
        <el-scrollbar>
          <h1>{{ $t('PhotographyView.cityList') }}</h1>
          <el-menu default-active="1" class="el-menu-vertical-demo">
            <el-menu-item index="1" @click="fetchImages('Banff')">
              <span>{{ $t('PhotographyView.banff') }}</span>
            </el-menu-item>
            <el-menu-item index="2" @click="fetchImages('Boston')">
              <span>{{ $t('PhotographyView.boston') }}</span>
            </el-menu-item>
            <el-menu-item index="3" @click="fetchImages('Cancun')">
              <span>{{ $t('PhotographyView.cancun') }}</span>
            </el-menu-item>
            <el-menu-item index="4" @click="fetchImages('YunNan')">
              <span>{{ $t('PhotographyView.yunnan') }}</span>
            </el-menu-item>
          </el-menu>
        </el-scrollbar>
      </el-aside>

      <el-container>
        <el-main>
          <el-scrollbar>
            <div v-if="images.length" class="image-list">
              <div v-for="image in images" :key="image" class="image-item">
                <img :src="image" :alt="image" />
              </div>
            </div>
            <div v-else>
              <p>{{ $t('PhotographyView.loading') }}</p>
            </div>
          </el-scrollbar>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';

const images = ref<string[]>([]);


const fetchImages = async (folderPath: string) => {
  try {
    const response = await axios.get('https://personal-website-yingchenarthurlius-projects.vercel.app/api/list-images', {
      params: { folder: folderPath }
    });
    images.value = response.data;
  } catch (error) {
    console.error('Error fetching images:', error);
  }
};

onMounted(() => {
  fetchImages('Banff');
});

</script>

<style scoped>
.photography {
  height: 100%;
  width: auto;
}

.el-aside {
  width: 200px;
}

span {
  font-size: 12px;
}

.image-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.image-item {
  margin: 10px;
}

.image-item img {
  max-width: 600px;
  height: auto;
}

@media (max-width: 768px) {
  .el-aside {
    width: 110px;
  }

  h1 {
    font-size: 12px;
  }

  span {
    font-size: 10px;
  }

  .image-item img {
    max-width: 100%;
    height: auto;
  }
}
</style>