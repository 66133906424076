<template>
  <div class="personalWebsite">
    <el-page-header :title="$t('PersonalWebsite.backButtonText')" @back="goBack">
      <template #breadcrumb>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/projects' }">
            {{ $t('PersonalWebsite.breadcrumbProjects') }}
          </el-breadcrumb-item>
          <el-breadcrumb-item id="title">{{ $t('PersonalWebsite.breadcrumbPersonalWebsite') }}</el-breadcrumb-item>
        </el-breadcrumb>
      </template>
      <template #content>
        <span>{{ $t('PersonalWebsite.breadcrumbPersonalWebsite') }}</span>
      </template>
    </el-page-header>
    <section class="module">
      <h1>{{ $t('PersonalWebsite.title') }}</h1>
      <p>{{ $t('PersonalWebsite.githubLink') }} <el-link type="primary"
          href="https://github.com/YingChenLiu18/PersonalWebsite"
          id="custom-link">https://github.com/YingChenLiu18/PersonalWebsite</el-link></p>
    </section>

    <section class="module">
      <h3>{{ $t('PersonalWebsite.overviewTitle') }}</h3>
      <p>{{ $t('PersonalWebsite.overviewText') }}</p>
    </section>

    <section class="module">
      <h3>{{ $t('PersonalWebsite.techStackTitle') }}</h3>
      <p>{{ $t('PersonalWebsite.techStackText') }}</p>
    </section>

    <section class="module">
      <h3>{{ $t('PersonalWebsite.uiDesignTitle') }}</h3>
      <div>
        <el-carousel :interval="5000" :direction="carouselDirection" arrow="always" type="card"
          indicator-position="none" :height="carouselHeight">
          <el-carousel-item v-for="(img, index) in images" :key="index">
            <img :src="img" class="carousel-image" />
          </el-carousel-item>
        </el-carousel>
      </div>
    </section>

    <section class="module">
      <h3>{{ $t('PersonalWebsite.featuresTitle') }}</h3>
      <ol>
        <li>
          <strong>{{ $t('PersonalWebsite.homeView.title') }}</strong>
          <ul>
            <li><strong>{{ $t('PersonalWebsite.homeView.overviewTitle') }}</strong> {{
              $t('PersonalWebsite.homeView.overviewText') }}</li>
            <li><strong>{{ $t('PersonalWebsite.homeView.toolkitTitle') }}</strong> {{
              $t('PersonalWebsite.homeView.toolkitText') }}</li>
          </ul>
        </li>
        <li>
          <strong>{{ $t('PersonalWebsite.aboutMeView.title') }}</strong>
          <ul>
            <li><strong>{{ $t('PersonalWebsite.aboutMeView.profileTitle') }}</strong> {{
              $t('PersonalWebsite.aboutMeView.profileText') }}</li>
          </ul>
        </li>
        <li>
          <strong>{{ $t('PersonalWebsite.worksView.title') }}</strong>
          <ul>
            <li><strong>{{ $t('PersonalWebsite.worksView.experienceTitle') }}</strong> {{
              $t('PersonalWebsite.worksView.experienceText') }}</li>
            <li><strong>{{ $t('PersonalWebsite.worksView.projectTitle') }}</strong> {{
              $t('PersonalWebsite.worksView.projectText') }}</li>
          </ul>
        </li>
        <li>
          <strong>{{ $t('PersonalWebsite.projectsView.title') }}</strong>
          <ul>
            <li><strong>{{ $t('PersonalWebsite.projectsView.overviewTitle') }}</strong> {{
              $t('PersonalWebsite.projectsView.overviewText') }}</li>
            <li><strong>{{ $t('PersonalWebsite.projectsView.detailTitle') }}</strong> {{
              $t('PersonalWebsite.projectsView.detailText') }}</li>
          </ul>
        </li>
        <li>
          <strong>{{ $t('PersonalWebsite.hobbysView.title') }}</strong>
          <ul>
            <li><strong>{{ $t('PersonalWebsite.hobbysView.galleryTitle') }}</strong> {{
              $t('PersonalWebsite.hobbysView.galleryText') }}</li>
          </ul>
        </li>
      </ol>
    </section>

    <section class="module">
      <h3>{{ $t('PersonalWebsite.architectureTitle') }}</h3>
      <img src="../../img/personalWebsite/architecture.png" alt="Architecture Diagram" id="architecture-diagram">
    </section>

    <section class="module">
      <h3>{{ $t('PersonalWebsite.installationTitle') }}</h3>
      <ol>
        <li>{{ $t('PersonalWebsite.installationSteps[0]') }}</li>
        <li>{{ $t('PersonalWebsite.installationSteps[1]') }}</li>
        <li>{{ $t('PersonalWebsite.installationSteps[2]') }}</li>
        <li>{{ $t('PersonalWebsite.installationSteps[3]') }}</li>
        <li>{{ $t('PersonalWebsite.installationSteps[4]') }}</li>
      </ol>
    </section>

    <section class="module">
      <h3>{{ $t('PersonalWebsite.futureImprovementsTitle') }}</h3>
      <ol>
        <li>{{ $t('PersonalWebsite.futureImprovements[0]') }}</li>
        <li>{{ $t('PersonalWebsite.futureImprovements[1]') }}</li>
        <li>{{ $t('PersonalWebsite.futureImprovements[2]') }}</li>
      </ol>
    </section>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router';

import image1 from '../../img/personalWebsite/HomePage.png';
import image2 from '../../img/personalWebsite/AboutMePage.png';
import image3 from '../../img/personalWebsite/WorksPage.png';
import image4 from '../../img/personalWebsite/ProjectsPage.png';
import image5 from '../../img/personalWebsite/BosAppPage.png';

const images = ref([
  image1,
  image2,
  image3,
  image4,
  image5
]);

function goBack() {
  router.push('/projects');
}

const isDesktop = ref(window.innerWidth >= 769);
const carouselHeight = ref(isDesktop.value ? '500px' : '360px');
const carouselDirection = ref(isDesktop.value ? 'horizontal' : 'vertical');

const updateLayout = () => {
  isDesktop.value = window.innerWidth >= 769;
  carouselHeight.value = isDesktop.value ? '500px' : '360px';
  carouselDirection.value = isDesktop.value ? 'horizontal' : 'vertical';
};

onMounted(() => {
  updateLayout();
  window.addEventListener('resize', updateLayout);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateLayout);
});

const router = useRouter();
</script>

<style scoped>
.personalWebsite {
  padding-left: 40px;
  padding-right: 40px;
}

#title {
  font-family: "Poppins";
  font-size: 14px;
}

#custom-link {
  font-size: 14px;
}

.module {
  margin-top: 40px;
}

.module h1 {
  font-size: 34px;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.module p {
  font-size: 14px;
  font-weight: 500;
}

.carousel-image {
  width: 100%;
  height: 450px;
  display: block;
  margin: 0 auto;
  object-fit: contain;
  border: 3px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box;
}

el-breadcrumb-item {
  font-size: 14px;
}

ol li {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
}

ul li {
  font-size: 14px;
  font-weight: 500;
}

#architecture-diagram {
  height: 600px;
  width: auto;
}

@media (max-width: 768px) {
  .personalWebsite {
    padding-left: 20px;
    padding-right: 20px;
  }

  #title {
    font-size: 10px;
  }

  #custom-link {
    font-size: 12px;
  }

  span {
    font-size: 12px;
  }

  .module {
    margin-top: 20px;
  }

  .module h1 {
    font-size: 24px;
  }

  .module p {
    font-size: 12px;
  }

  .carousel-image {
    width: 100%;
    height: auto;
    border: 1px solid #ccc;
    border-radius: 8px;
  }

  ol li {
    font-size: 12px;
  }

  ul li {
    font-size: 12px;
    font-weight: 500;
  }

  #architecture-diagram {
    height: 200px;
    width: auto;
  }
}
</style>