<template>
  <div class="main">
    <el-container id="full-screen">
      <el-header v-if="isDesktop">
        <el-menu :default-active="'0'" class="el-menu-demo" mode="horizontal" :ellipsis="false" :router="true"
          text-color="#303133">
          <el-menu-item index="0" route="/home">
            <img id="website-logo" src="../src/img/YingChenLiuLogo.png" alt="Element logo" />
          </el-menu-item>

          <div class="flex-grow"></div>

          <el-menu-item index="1"><el-switch v-model="isChinese" active-text="中文" @change="toggleLanguage"
              @click.stop></el-switch></el-menu-item>
          <el-menu-item index="2" route="/home">{{ $t('appView.home') }}</el-menu-item>
          <el-menu-item index="3" route="/about">{{ $t('appView.aboutMe') }}</el-menu-item>
          <el-menu-item index="4" route="/works">{{ $t('appView.works') }}</el-menu-item>
          <el-menu-item index="5" route="/projects">{{ $t('appView.project') }}</el-menu-item>
          <el-menu-item index="6" route="/photography">{{ $t('appView.photography') }}</el-menu-item>
        </el-menu>
      </el-header>

      <el-header v-else>
        <el-menu :default-active="'0'" class="el-menu-demo" mode="horizontal" :ellipsis="false" :router="true"
          :menu-trigger="'click'" :close-on-click-outside="true" text-color="#303133">
          <el-menu-item index="0" route="/home">
            <img id="website-logo" src="../src/img/YingChenLiuLogo.png" alt="Element logo" />
          </el-menu-item>

          <div class="flex-grow"></div>
          <el-menu-item index="1" id="changeLanguage"><el-switch v-model="isChinese" active-text="中文"
              @change="toggleLanguage" @click.stop></el-switch></el-menu-item>
          <el-sub-menu index="2" route="/home">
            <template #title>{{ $t('appView.view') }}</template>
            <el-menu-item index="2-1" route="/home">{{ $t('appView.home') }}</el-menu-item>
            <el-menu-item index="2-2" route="/about">{{ $t('appView.aboutMe') }}</el-menu-item>
            <el-menu-item index="2-3" route="/works">{{ $t('appView.works') }}</el-menu-item>
            <el-menu-item index="2-4" route="/projects">{{ $t('appView.project') }}</el-menu-item>
            <el-menu-item index="2-5" route="/photography">{{ $t('appView.photography') }}</el-menu-item>
          </el-sub-menu>
        </el-menu>
      </el-header>

      <el-main id="main-content">
        <router-view />
      </el-main>
    </el-container>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { useI18n } from 'vue-i18n';

const { locale } = useI18n({ useScope: 'global' });

const isDesktop = ref(window.innerWidth >= 769);
const isChinese = ref(locale.value === 'zh');

const updateLayout = () => {
  isDesktop.value = window.innerWidth >= 769;
};

const toggleLanguage = () => {
  const newLang = isChinese.value ? 'zh' : 'en';
  locale.value = newLang;
  localStorage.setItem('locale', newLang);
};

onMounted(() => {
  updateLayout();
  console.log('Initial isDesktop:', isDesktop.value);
  window.addEventListener('resize', updateLayout);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateLayout);
});
</script>

<style scoped>
.main {
  font-family: "Poppins";
  font-weight: 600;
  font-style: normal;
  overflow: hidden;
}

#full-screen {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.flex-grow {
  flex-grow: 1;
}

#main-content {
  flex-grow: 1;
  overflow: auto;
}

#website-logo {
  width: 140px;
  height: auto;
}

@media (max-width: 768px) {
  #full-screen {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  #website-logo {
    width: 100px;
    height: auto;
  }
}
</style>

<style>
.el-switch__label {
  height: 100%;
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {

  .el-menu--collapse .el-menu .el-submenu,
  .el-menu--popup {
    min-width: 100px;
    font-family: "Poppins";
    font-weight: 500;
  }

  #changeLanguage {
    width: 100px;
  }

}
</style>