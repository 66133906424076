import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/HomeView.vue'
import About from '../views/AboutView.vue'
import Works from '../views/WorksView.vue'
import Projects from '../views/ProjectsView.vue'
import Photography from '../views/PhotographyView.vue'
import BostonApp from '../views/projectsViews/BostonApp.vue'
import PersonalWebsite from '../views/projectsViews/PersonalWebsite.vue'
import SkyTakeOut from '../views/projectsViews/SkyTakeOut.vue'

const routes: Array<RouteRecordRaw> = [
  { path: '/', redirect: '/home' },
  { path: '/home', name: 'Home', component: Home },
  { path: '/about', name: 'About', component: About },
  { path: '/works', name: 'Works', component: Works },
  { path: '/projects', name: 'Projects', component: Projects },
  { path: '/photography', name: 'Photography', component: Photography },
  { path: '/projects/bostonApp', name: 'BostonApp', component: BostonApp },
  { path: '/projects/personalWebsite', name: 'PersonalWebsite', component: PersonalWebsite },
  { path: '/projects/skyTakeOut', name: 'SkyTakeOut', component: SkyTakeOut },
  { path: '/:pathMatch(.*)*', redirect: '/home' }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router