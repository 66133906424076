<template>
  <div class="bostonApp">
    <el-page-header :title="$t('BostonApp.backButtonText')" @back="goBack">
      <template #breadcrumb>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/projects' }">
            {{ $t('BostonApp.breadcrumbProjects') }}
          </el-breadcrumb-item>
          <el-breadcrumb-item id="title">{{ $t('BostonApp.breadcrumbBostonApp') }}</el-breadcrumb-item>
        </el-breadcrumb>
      </template>
      <template #content>
        <span>{{ $t('BostonApp.breadcrumbBostonApp') }}</span>
      </template>
    </el-page-header>
    <section class="module">
      <h1>{{ $t('BostonApp.title') }}</h1>
      <p>{{ $t('BostonApp.githubLink') }} <el-link type="primary"
          href="https://github.com/YingChenLiu18/Boston-Intelligent-Transportation-Application"
          id="custom-link">https://github.com/YingChenLiu18/Boston-Intelligent-Transportation-Application</el-link>
      </p>
    </section>

    <section class="module">
      <h3>{{ $t('BostonApp.overviewTitle') }}</h3>
      <p>{{ $t('BostonApp.overviewText') }}</p>
    </section>

    <section class="module">
      <h3>{{ $t('BostonApp.techStackTitle') }}</h3>
      <p>{{ $t('BostonApp.techStackText') }}</p>
    </section>

    <section class="module">
      <h3>{{ $t('BostonApp.uiDesignTitle') }}</h3>
      <div>
        <el-carousel :interval="5000" arrow="always" type="card" indicator-position="none" :height="carouselHeight">
          <el-carousel-item v-for="(img, index) in images" :key="index">
            <img :src="img" class="carousel-image" />
          </el-carousel-item>
        </el-carousel>
      </div>
    </section>

    <section class="module">
      <h3>{{ $t('BostonApp.featuresTitle') }}</h3>
      <ol>
        <li>
          <strong>{{ $t('BostonApp.accountRegistrationTitle') }}</strong>
          <ul>
            <li><strong>{{ $t('BostonApp.registerAccount') }}</strong> {{ $t('BostonApp.registerAccountText') }}</li>
            <li><strong>{{ $t('BostonApp.loginAccount') }}</strong> {{ $t('BostonApp.loginAccountText') }}</li>
          </ul>
        </li>
        <li>
          <strong>{{ $t('BostonApp.cardManagementTitle') }}</strong>
          <ul>
            <li><strong>{{ $t('BostonApp.eCard') }}</strong> {{ $t('BostonApp.eCardText') }}</li>
            <li><strong>{{ $t('BostonApp.accountInfo') }}</strong> {{ $t('BostonApp.accountInfoText') }}</li>
          </ul>
        </li>
        <li>
          <strong>{{ $t('BostonApp.farePaymentTitle') }}</strong>
          <ul>
            <li><strong>{{ $t('BostonApp.qrCodePayment') }}</strong> {{ $t('BostonApp.qrCodePaymentText') }}</li>
            <li><strong>{{ $t('BostonApp.tapPayment') }}</strong> {{ $t('BostonApp.tapPaymentText') }}</li>
          </ul>
        </li>
        <li>
          <strong>{{ $t('BostonApp.rechargingTitle') }}</strong>
          <ul>
            <li><strong>{{ $t('BostonApp.recharging') }}</strong> {{ $t('BostonApp.rechargingText') }}</li>
            <li><strong>{{ $t('BostonApp.dayPassPurchase') }}</strong> {{ $t('BostonApp.dayPassPurchaseText') }}</li>
          </ul>
        </li>
        <li>
          <strong>{{ $t('BostonApp.transactionHistoryTitle') }}</strong>
          <ul>
            <li><strong>{{ $t('BostonApp.rideHistory') }}</strong> {{ $t('BostonApp.rideHistoryText') }}</li>
            <li><strong>{{ $t('BostonApp.rechargeHistory') }}</strong> {{ $t('BostonApp.rechargeHistoryText') }}</li>
          </ul>
        </li>
        <li>
          <strong>{{ $t('BostonApp.userProfileTitle') }}</strong>
          <ul>
            <li><strong>{{ $t('BostonApp.profile') }}</strong> {{ $t('BostonApp.profileText') }}</li>
            <li><strong>{{ $t('BostonApp.accountSettings') }}</strong> {{ $t('BostonApp.accountSettingsText') }}</li>
          </ul>
        </li>
        <li>
          <strong>{{ $t('BostonApp.securityFeaturesTitle') }}</strong>
          <ul>
            <li><strong>{{ $t('BostonApp.dataProtection') }}</strong> {{ $t('BostonApp.dataProtectionText') }}</li>
          </ul>
        </li>
      </ol>
    </section>

    <section class="module">
      <h3>{{ $t('BostonApp.architectureTitle') }}</h3>
      <img src="../../img/bosApp/architecture.png" alt="Architecture Diagram" id="architecture-diagram">
    </section>

    <section class="module">
      <h3>{{ $t('BostonApp.installationTitle') }}</h3>
      <ol>
        <li>{{ $t('BostonApp.installationSteps[0]') }}</li>
        <li>{{ $t('BostonApp.installationSteps[1]') }}</li>
        <li>{{ $t('BostonApp.installationSteps[2]') }}</li>
        <li>{{ $t('BostonApp.installationSteps[3]') }}</li>
        <li>{{ $t('BostonApp.installationSteps[4]') }}</li>
      </ol>
    </section>

    <section class="module">
      <h3>{{ $t('BostonApp.futureImprovementsTitle') }}</h3>
      <ol>
        <li>{{ $t('BostonApp.futureImprovements[0]') }}</li>
        <li>{{ $t('BostonApp.futureImprovements[1]') }}</li>
        <li>{{ $t('BostonApp.futureImprovements[2]') }}</li>
        <li>{{ $t('BostonApp.futureImprovements[3]') }}</li>
      </ol>
    </section>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router';

import image1 from '../../img/bosApp/home_page.png';
import image2 from '../../img/bosApp/register_page.png';
import image3 from '../../img/bosApp/signin_page.png';
import image4 from '../../img/bosApp/card_page.png';
import image5 from '../../img/bosApp/pay_page.png';
import image6 from '../../img/bosApp/recharge_page1.png';
import image7 from '../../img/bosApp/recharge_page2.png';
import image8 from '../../img/bosApp/purchase_page.png';
import image9 from '../../img/bosApp/profile_page.png';
import image10 from '../../img/bosApp/recharge_history_page.png';
import image11 from '../../img/bosApp/ride_history_page.png';

const images = ref([
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11
]);

const router = useRouter();

function goBack() {
  router.push('/projects');
}

const isDesktop = ref(window.innerWidth >= 769);
const carouselHeight = ref(isDesktop.value ? '500px' : '250px');

const updateLayout = () => {
  isDesktop.value = window.innerWidth >= 769;
  carouselHeight.value = isDesktop.value ? '500px' : '250px';
};

onMounted(() => {
  updateLayout();
  window.addEventListener('resize', updateLayout);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateLayout);
});
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap');

.bostonApp {
  padding-left: 40px;
  padding-right: 40px;
}

#title {
  font-family: "Poppins";
  font-size: 14px;
}

#custom-link {
  font-size: 14px;
}

.module {
  margin-top: 40px;
}

span {
  font-size: 14px;
}

.module h1 {
  font-size: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.module p {
  font-size: 14px;
  font-weight: 500;
}

.carousel-image {
  height: 100%;
  width: auto;
  display: block;
  margin: 0 auto;
  object-fit: contain;
  border: 3px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box;
}

el-breadcrumb-item {
  font-size: 14px;
}

ol li {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
}

ul li {
  font-size: 14px;
  font-weight: 500;
}

#architecture-diagram {
  height: 600px;
  width: auto;
}

@media (max-width: 768px) {
  .bostonApp {
    padding-left: 20px;
    padding-right: 20px;
  }

  #title {
    font-size: 10px;
  }

  #custom-link {
    font-size: 12px;
  }

  span {
    font-size: 12px;
  }

  .module {
    margin-top: 20px;
  }

  .module h1 {
    font-size: 24px;
  }

  .module p {
    font-size: 12px;
  }

  .carousel-image {
    height: 250px;
    width: auto;

    border: 1px solid #ccc;
    border-radius: 8px;
  }

  ol li {
    font-size: 12px;
  }

  ul li {
    font-size: 12px;
    font-weight: 500;
  }

  #architecture-diagram {
    height: 200px;
    width: auto;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .carousel-image {
    height: 500px;
    width: auto;

    border: 1px solid #ccc;
    border-radius: 8px;
  }
}
</style>
