import { createI18n } from 'vue-i18n';
import en from './locales/en.json';
import zh from './locales/zh.json';

const messages = {
    en,
    zh
};

// 从 localStorage 读取语言设置
const savedLocale = localStorage.getItem('locale') || 'en';

const i18n = createI18n({
    legacy: false, // 确保 legacy 模式为 false
    locale: savedLocale,
    messages,
});

export default i18n;