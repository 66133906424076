<template>
  <div class="work">
    <div class="module" id="deepblue">
      <div id="work-beginnerTemplate">
        <div>
          <h4>{{ $t('WorksView.deepblueTitle') }}</h4>
          <h5>{{ $t('WorksView.deepblueDate') }}</h5>
        </div>
        <div id="companyInformation_combo">
          <img src="../img/DeepBlue.png" alt="DeepBlue" id="deepblue" />
          <div id="nameAndAddress">
            <h4><a href="https://en.deepblueai.com" target="_blank" rel="noopener noreferrer">
                {{ $t('WorksView.deepblueCompany') }}
              </a></h4>
            <h4>{{ $t('WorksView.deepblueLocation') }}</h4>
          </div>
        </div>
      </div>

      <div class="collapse">
        <el-collapse v-model="activeNames">
          <el-collapse-item name="1">
            <template #title>
              <p id="title">{{ $t('WorksView.projectDescriptionTitle') }}</p>
            </template>
            <p>{{ $t('WorksView.projectDescriptionText') }}</p>
            <ul>
              <li>
                <strong>{{ $t('WorksView.detectingEmergencies') }}</strong>
                {{ $t('WorksView.detectingEmergenciesText') }}
              </li>
              <li>
                <strong>{{ $t('WorksView.detectingTrash') }}</strong>
                {{ $t('WorksView.detectingTrashText') }}
              </li>
              <li>
                <strong>{{ $t('WorksView.detectingVandalism') }}</strong>
                {{ $t('WorksView.detectingVandalismText') }}
              </li>
              <li>
                <strong>{{ $t('WorksView.detectingSmoking') }}</strong>
                {{ $t('WorksView.detectingSmokingText') }}
              </li>
              <li>
                <strong>{{ $t('WorksView.detectingVehicles') }}</strong>
                {{ $t('WorksView.detectingVehiclesText') }}
              </li>
              <li>
                <strong>{{ $t('WorksView.detectingObstruction') }}</strong>
                {{ $t('WorksView.detectingObstructionText') }}
              </li>
            </ul>
            <h4>{{ $t('WorksView.popularFeaturesTitle') }}</h4>
            <ul>
              <li>
                <strong>{{ $t('WorksView.emergencyDetection') }}</strong>
                {{ $t('WorksView.emergencyDetectionText') }}
              </li>
              <li>
                <strong>{{ $t('WorksView.ropeMonitoring') }}</strong>
                {{ $t('WorksView.ropeMonitoringText') }}
              </li>
              <li>
                <strong>{{ $t('WorksView.trashMonitoring') }}</strong>
                {{ $t('WorksView.trashMonitoringText') }}
              </li>
            </ul>
          </el-collapse-item>
          <el-collapse-item name="2">
            <template #title>
              <p id="title">{{ $t('WorksView.responsibilitiesTitle') }}</p>
            </template>
            <div>
              <ul>
                <li>
                  <strong>{{ $t('WorksView.algorithmTesting') }}</strong>
                  {{ $t('WorksView.algorithmTestingText') }}
                  <strong id="red-text">{{ $t('WorksView.algorithmTestingHighlight') }}</strong>
                  {{ $t('WorksView.algorithmTestingText2') }}
                </li>
                <li>
                  <strong>{{ $t('WorksView.algorithmValidation') }}</strong>
                  {{ $t('WorksView.algorithmValidationText') }}
                  <strong id="red-text">{{ $t('WorksView.algorithmValidationHighlight') }}</strong>
                </li>
                <li>
                  <strong>{{ $t('WorksView.technicalMonitoring') }}</strong>
                  {{ $t('WorksView.technicalMonitoringText') }}
                  <strong id="red-text">{{ $t('WorksView.technicalMonitoringHighlight') }}</strong>
                  {{ $t('WorksView.technicalMonitoringText2') }}
                </li>
                <li>
                  <strong>{{ $t('WorksView.dataProcessing') }}</strong>
                  {{ $t('WorksView.dataProcessingText') }}
                </li>
                <li>
                  <strong>{{ $t('WorksView.storageManagement') }}</strong>
                  {{ $t('WorksView.storageManagementText') }}
                </li>
                <li>
                  <strong>{{ $t('WorksView.networkConnection') }}</strong>
                  {{ $t('WorksView.networkConnectionText') }}
                </li>
              </ul>
            </div>
          </el-collapse-item>
          <el-collapse-item name="3">
            <template #title>
              <p id="title">{{ $t('WorksView.technologyStackToolsTitle') }}</p>
            </template>
            <ul>
              <li><strong>{{ $t('WorksView.technologyStack') }}</strong>C, Python, Shell, Linux </li>
              <li><strong>{{ $t('WorksView.tools') }}</strong>Ubuntu, Docker, Conda, Jira, Git, VoTT </li>
            </ul>
          </el-collapse-item>
        </el-collapse>
      </div>

    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import '../css/WorksViewCss.css'

const activeNames = ref(['0'])

</script>