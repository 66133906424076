<template>
  <div class="about">
    <div class="module" id="education">
      <h3>{{ $t('AboutView.education') }}</h3>
      <hr>
      <div id="bu">
        <div class="university">
          <div>
            <h4>{{ $t('AboutView.master') }}</h4>
            <h5>{{ $t('AboutView.masterDate') }}</h5>
          </div>
          <div class="university_combo">
            <img src="../img/Boston_University.png" alt="BU-Logo" />
            <div class="school_address">
              <h4><a href="https://www.bu.edu/" target="_blank" rel="noopener noreferrer">
                  {{ $t('AboutView.bostonUniversity') }}
                </a></h4>
              <h4>{{ $t('AboutView.bostonUniversityAddress') }}</h4>
            </div>
          </div>
        </div>
        <div class="related-course">
          <h5>{{ $t('AboutView.relatedCourse') }}</h5>
          <p>{{ $t('AboutView.bostonCourse') }}</p>
        </div>
      </div>

      <div id="york">
        <div class="university">
          <div>
            <h4>{{ $t('AboutView.bachelor') }}</h4>
            <h5>{{ $t('AboutView.bachelorDate') }}</h5>
          </div>
          <div class="university_combo">
            <img src="../img/York_University.png" alt="York-Logo" />
            <div class="school_address">
              <h4>
                <a href="https://www.yorku.ca" target="_blank" rel="noopener noreferrer">
                  {{ $t('AboutView.yorkUniversity') }}
                </a>
              </h4>
              <h4>{{ $t('AboutView.yorkUniversityAddress') }}</h4>
            </div>
          </div>
        </div>
        <div class="related-course">
          <h5>{{ $t('AboutView.relatedCourse') }}</h5>
          <p>{{ $t('AboutView.yorkCourse') }}</p>
        </div>
      </div>
    </div>

    <div class="module" id="skills">
      <h3>{{ $t('AboutView.skills') }}</h3>
      <hr>
      <h5>{{ $t('AboutView.frontEnd') }}</h5>
      <p>Html, CSS, JavaScript, Vue.js, Ajax, Element</p>
      <h5>{{ $t('AboutView.backEnd') }}</h5>
      <p>Java, Kotlin, R, Spring, SpringMVC, Spring Boot, MyBatis, Tomcat, JWT, Filter,
        Interceptor</p>
      <h5>{{ $t('AboutView.database') }}</h5>
      <p>MySQL, Redis, FireBase</p>
      <h5>{{ $t('AboutView.cloudService') }}</h5>
      <p>{{ $t('AboutView.aliyunOss') }}</p>
      <h5>{{ $t('AboutView.developmentTool') }}</h5>
      <p>IntelliJ, VS Code, GitHub, Android Studio, Rstudio, ApiFox, Postman, Swagger, Figma
      </p>
      <h5>{{ $t('AboutView.dataStructures') }}</h5>
      <el-link href="https://leetcode.cn/u/liu-ying-chen/" target="_blank" style="margin-bottom: 10px;">{{
        $t('AboutView.leetcodeLink') }}</el-link>
      <div id="leetcard">

      </div>
      <p>{{ $t('AboutView.algorithmList') }}
      </p>
    </div>

    <div class="module" id="self-introduction">
      <h3>{{ $t('AboutView.selfIntroduction') }}</h3>
      <hr>
      <ul id="left-aligned">
        <li>
          <strong>{{ $t('AboutView.deepProgramming') }}</strong>
          {{ $t('AboutView.deepProgrammingText') }}
        </li>
        <li>
          <strong>{{ $t('AboutView.excellentCode') }}</strong>
          {{ $t('AboutView.excellentCodeText') }}
        </li>
        <li>
          <strong>{{ $t('AboutView.attentionToDetail') }}</strong>
          {{ $t('AboutView.attentionToDetailText') }}
        </li>
        <li>
          <strong>{{ $t('AboutView.selfDirected') }}</strong>
          {{ $t('AboutView.selfDirectedText') }}
        </li>
      </ul>
    </div>

    <div class="module" id="github">
      <h3>{{ $t('AboutView.githubProjects') }}</h3>
      <hr>
      <div v-for="project in projects" :key="project.id" style="margin-bottom: 20px;">
        <el-card class="box-card">
          <template v-slot:header>
            <div class="clearfix">
              <el-link :underline="true" @click.prevent="() => openLink(project.html_url)">
                {{ project.name }}
              </el-link>
            </div>
          </template>
          <p>{{ project.description }}</p>
          <div style="margin-top: 10px;">
            <el-tag>{{ project.language }}</el-tag>
          </div>
        </el-card>
      </div>
    </div>

    <div class="module" id="resume">
      <h3>{{ $t('AboutView.resume') }}</h3>
      <hr>
      <el-button type="primary" @click="openDialog">{{ $t('AboutView.downloadResume') }}</el-button>

      <el-dialog v-model="dialogVisible" :title="$t('AboutView.chooseResumeLanguage')">
        <span>{{ $t('AboutView.selectResumeLanguage') }}</span>
        <div id="download_resume">
          <el-button type="primary" @click="downloadResume('zh')">{{ $t('AboutView.downloadResumeZh') }}</el-button>
          <el-button type="primary" @click="downloadResume('en')">{{ $t('AboutView.downloadResumeEn') }}</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<style scoped>
#leetcode_card #column-layout {
  display: flex;
  flex-direction: row;
  align-items: center;
}

:deep(.el-link__inner) {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 16px;
}

.box-card p {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 14px;
  color: black;
}

.el-card {
  width: 50%;
}

:deep(.el-dialog) {
  width: 30% !important;
}

@media (max-width: 768px) {
  .el-card {
    width: 100%;
  }

  :deep(.el-dialog) {
    width: 80% !important;
  }



}
</style>

<script lang="ts" setup>
import '../css/AboutViewCss.css';
import { ref, onMounted } from 'vue';
import axios from 'axios';


const fetchLeetCard = () => {
  axios.get('https://leetcard.jacoblin.cool/liu-ying-chen?theme=nord&font=Poppins&ext=heatmap&site=cn')
    .then(response => {
      const svgData = response.data;
      const element = document.getElementById('leetcard');
      if (element !== null) {
        element.innerHTML = svgData;
      }
    })
    .catch(error => {
      console.error('Error fetching the SVG:', error);
    });
};

//GitHub function
interface GitHubProject {
  id: number;
  name: string;
  html_url: string;
  description: string;
  language: string;
}

const projects = ref<GitHubProject[]>([]);

const fetchGitHubProjects = async () => {
  try {
    const response = await axios.get('https://api.github.com/users/YingChenLiu18/repos');
    projects.value = response.data;
  } catch (err) {
    console.error('Error fetching GitHub projects:', err);
  }
};

const openLink = (url: string) => {
  window.open(url, '_blank');
};

//Resume function
const dialogVisible = ref(false);

const openDialog = () => {
  dialogVisible.value = true;
};

const downloadResume = async (lang: string) => {
  const resumeUrl = lang === 'zh'
    ? '/api/YingChenLiu-Resume-C.pdf'
    : '/api/YingChenLiu-Resume-E.pdf';

  try {
    const response = await axios.get(resumeUrl, {
      responseType: 'blob'
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', lang === 'zh' ? '简历_中文.pdf' : 'Resume_English.pdf');
    document.body.appendChild(link);
    link.click();
    link.remove();
    dialogVisible.value = false;
  } catch (error) {
    console.error('Error downloading resume:', error);
  }
};

onMounted(() => {
  fetchGitHubProjects();
  fetchLeetCard();
});
</script>
